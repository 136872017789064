
import {
  ArchiveConsultantBankAccountMutation,
  ArchiveConsultantBankAccountMutationMutation,
  ArchiveConsultantPayoneerAccountMutation,
  ArchiveConsultantPayoneerAccountMutationMutation,
  ConsultantBankAccount,
  ConsultantPayoneerAccount,
  ConsultantBillingAccountsQuery,
} from "@/gql"
import { Vue, Component, Prop } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import BillingAccountForm from "@/components/forms/BillingAccountForm.vue"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"
import { NetworkStatus } from "@apollo/client/core"
import Loader from "@/components/widgets/common/Loader.vue"

@Component({
  components: {
    AppPage,
    BillingAccountForm,
    Loader,
  },
  apollo: {
    accounts: {
      query: ConsultantBillingAccountsQuery,

      result(result) {
        if (result.networkStatus === NetworkStatus.ready) {
          this.isLoading = false
        }
      },
      error(error) {
        if (error.graphQLErrors) {
          this.errorLoading = true
        } else if (error.networkError) {
          this.networkError = true
        }
      },
      update(data) {
        return data?.consultantProfile
      },
    },
  },
})
export default class Profile extends Vue {
  @Prop() readonly queries?: RefetchQueryDescription

  showDeleteDialog = false
  showPaymentModal = false
  isLoading = true
  actionLoading = false
  errorLoading = false
  activeAccount: ConsultantBankAccount | ConsultantPayoneerAccount | null = null
  activeAccountName = ""

  accounts: ConsultantBankAccount[] | ConsultantPayoneerAccount[] | null = null

  onPayoneerAccountDelete(account: ConsultantPayoneerAccount) {
    if (!account) return
    this.activeAccount = account
    this.activeAccountName = account.customerId
    this.showDeleteDialog = true
  }

  onBankAccountDelete(account: ConsultantBankAccount) {
    if (!account) return
    this.activeAccount = account
    this.activeAccountName = account.bankName
    this.showDeleteDialog = true
  }

  onDeleteBillingAccount() {
    const account = this.activeAccount
    if (!account) return
    this.actionLoading = true
    if ((account as ConsultantPayoneerAccount).customerId) {
      this.archivePayoneerAccount(account!.id)
    } else {
      this.archiveBankAccount(account!.id)
    }
  }

  onEditBillingAccount(account: ConsultantBankAccount | ConsultantPayoneerAccount) {
    this.activeAccount = account
    this.showPaymentModal = true
  }

  async archivePayoneerAccount(id: number) {
    const result = await this.mutate<ArchiveConsultantPayoneerAccountMutationMutation>({
      mutation: ArchiveConsultantPayoneerAccountMutation,
      variables: { accountId: id },
      done: () => {
        this.$apollo.queries.accounts.refetch()
        this.actionLoading = false
      },
    })

    if (result.data && !result.data.archiveConsultantPayoneerAccount.error) {
      this.addSuccess("Account removed successfully")
      this.showDeleteDialog = false
      this.activeAccount = null
    }
  }

  async archiveBankAccount(id: number) {
    const result = await this.mutate<ArchiveConsultantBankAccountMutationMutation>({
      mutation: ArchiveConsultantBankAccountMutation,
      variables: { accountId: id },
      done: () => {
        this.$apollo.queries.accounts.refetch()
        this.actionLoading = false
      },
    })

    if (result.data && !result.data.archiveConsultantBankAccount.error) {
      this.addSuccess("Account removed successfully")
      this.showDeleteDialog = false
      this.activeAccount = null
    }
  }
}
